import React from 'react';
import {Link} from "react-router-dom";

const AML = () => {
    return (
        <div>
            <div className="theme-inner-banner">
                <div className="container">
                    <h2 className="intro-title text-center">AML</h2>
                    <ul className="page-breadcrumb style-none d-flex justify-content-center">
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/services'>Services</Link></li>
                        <li className="current-page">AML</li>
                    </ul>
                </div>
                <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two"/>
            </div>

            <div className="fancy-feature-twentyFour">
                <div className="container">
                    <div className="row gx-xxl-5">
                        <div className="col-lg-6 col-sm-12 mb-40 xs-mb-30" data-aos="fade-up" style={{
                            padding: '16px',
                        }}>
                            <div style={{
                                backgroundColor: '#ffffff',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                padding: '16px',
                                borderRadius: '8px',
                            }}>
                                <div>AML Compliance Services for Licensed Money Lenders</div>

                                <div>Ensure compliance with Anti-Money Laundering (AML) regulations through our AML
                                    Independent
                                    Audit Report services, enhanced by Regulatory Technology (RegTech) and AI-powered
                                    approval
                                    systems.
                                </div>
                                <br/>

                                <div>Our Services for Licensed Money Lenders:</div>
                                <ul>
                                    <li>AI-powered compliance annual service package</li>
                                    <ul>
                                        <li>Transaction surveillance</li>
                                        <li>Client due diligence exercises</li>
                                        <li>Regulatory and market alerts</li>
                                        <li>Staff training and education</li>
                                    </ul>
                                    <li>Independent annual AML audit reports</li>
                                    <li>AML compliance reviews and risk assessments.</li>
                                    <li>AML compliance consultancy</li>
                                </ul>
                                <div>Why Choose Us?</div>
                                <ul>
                                    <li>Expert team with global compliance experience.</li>
                                    <li>Cutting-edge AI and RegTech tools for efficiency and accuracy.</li>
                                    <li>Strict confidentiality and security.</li>

                                </ul>
                                <br/>
                                <div>Contact Us Today!</div>
                                <br/>
                                <div>Email: <a href={"mailto:aml@snstec.com"}>aml@snstec.com</a></div>
                                <div>Phone: +852 9212 2324</div>
                                <div>Website: <a target="_blank" href="https://snstec.com/services/aml" rel="noopener noreferrer">www.snstec.com/services/aml</a>
                                </div>

                                <div>Stay compliant with the power of RegTech and AI!</div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-12 mb-40 xs-mb-30" data-aos="fade-up" style={{
                            padding: '16px',
                        }}>
                            <div style={{
                                backgroundColor: '#ffffff',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                padding: '16px',
                                borderRadius: '8px',
                            }}>
                                <div>反洗錢獨立審計報告服務</div>
                                <div>透過結合監管科技（RegTech）和AI審批系統，我們提供全面的反洗錢獨立審計報告服務，助您確保合規並降低風險。</div>
                                <br/>
                                <div>服務內容：</div>
                                <ul>
                                    <li>人工智慧合規年度服務套餐</li>
                                    <ul>
                                        <li>交易監控</li>

                                        <li>客戶盡職調查</li>

                                        <li>監管和市場警報</li>

                                        <li>員工訓練與教育</li>
                                    </ul>
                                    <li>獨立年度反洗錢審計報告</li>
                                    <li>反洗錢合規審查與風險評估。</li>
                                    <li>反洗錢合規諮詢</li>
                                </ul>
                                <br/>
                                <div>為何選擇我們？</div>
                                <ul>
                                    <li>擁有豐富經驗的專業團隊</li>
                                    <li>採用先進AI與監管科技，提升效率與準確性</li>
                                    <li>嚴格保密與數據安全</li>
                                </ul>
                                <br/>
                                <div>立即聯繫我們！</div>
                                <br/>
                                <div>郵箱: <a href={"mailto:aml@snstec.com"}>aml@snstec.com</a></div>
                                <div>電話: +852 9212 2324</div>
                                <div>網站: <a target="_blank" href="https://snstec.com/services/aml" rel="noopener noreferrer">www.snstec.com/services/aml</a>
                                </div>
                                <div>借助監管科技與AI，助您輕鬆合規！</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{height: '100px', width: '100%'}}/>
            </div>

        </div>
    );
};

export default AML;