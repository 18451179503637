import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from "./pages/Home";
import Header from './components/Header';
import Contact from "./pages/Contact";
import About from "./pages/About";
import Services from "./pages/Services";
import AI from "./pages/AI";
import MachineLearning from "./pages/MachineLearning";
import ClientSupport from "./pages/ClientSupport";
import Privacy from "./pages/Privacy";
import Footer from "./components/Footer";
import Terms from "./pages/Terms";
import TC from "./pages/TC";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import ScrollToTop from './utils/scrollToTop';
import AML from "./pages/AML";


function App() {
    return (
        <Router basename={'/'}>
            <ScrollToTop/>
            <div className="main-page-wrapper">
                <Header></Header>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/ai" element={<AI/>}/>
                    <Route path="/machineLearning" element={<MachineLearning/>}/>
                    <Route path="/clientSupport" element={<ClientSupport/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/services/aml" element={<AML/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/tc" element={<TC/>}/>
                    <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
                    <Route path="/cookiepolicy" element={<CookiePolicy/>}/>
                </Routes>
                <Footer></Footer>
            </div>
        </Router>
    );
}

export default App;
