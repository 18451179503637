import React, {useCallback} from 'react';
import {Link, useLocation} from "react-router-dom";

const Header = () => {
    const navigate = useLocation();
    const isActive = useCallback((path:string):string => {
       return navigate.pathname===path?'active':'';
    }, [navigate.pathname])
    return (
        <div>
            <div className="offcanvas offcanvas-top theme-search-form justify-content-center" id="offcanvasTop">
                <button type="button" className="close-btn tran3s" data-bs-dismiss="offcanvas" aria-label="Close"><i
                    className="bi bi-x-lg"></i></button>
                <div className="form-wrapper">
                    <form action="#">
                        <input type="text" placeholder="Search Keyword...."/>
                    </form>
                </div>
            </div>

            <header className="theme-main-menu sticky-menu theme-menu-one">
                <div className="inner-content">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="logo order-lg-0">
                            <Link to="/" className="d-block">
                                <img src="images/logo/logo_01.png" alt="" width="130"/>
                            </Link>
                        </div>
                        <div className="right-widget d-flex align-items-center ms-auto ms-lg-0 order-lg-3">

                        </div>

                        <nav className="navbar navbar-expand-lg order-lg-2">
                            <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav">
                                    <li className="d-block d-lg-none">
                                        <div className="logo">
                                            <Link to="/">
                                                <img src="images/logo/logo_01.png" alt="" width="130"/>
                                            </Link>
                                        </div>
                                    </li>

                                    <li className={`${isActive('/')} nav-item`}>
                                        <Link to="/" className="nav-link" role="button">Home</Link>
                                    </li>
                                    <li className={`${isActive('/about')} nav-item`}>
                                        <Link to="/about" className="nav-link" role="button">About Us</Link>
                                    </li>
                                    <li className={`${isActive('/services')} nav-item`}>
                                        <Link to="/services" className="nav-link" role="button">Services</Link>
                                    </li>
                                    <li className={`${isActive('/contact')} nav-item`}>
                                        <Link to="/contact" className="nav-link" role="button">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;