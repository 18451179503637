import React from 'react';
import WorkProcess from "../components/WorkProcess";
import {Link} from "react-router-dom";

const Services = () => {
    return (
        <div>
            <div className="theme-inner-banner">
                <div className="container">
                    <h2 className="intro-title text-center">Our Services</h2>
                    <ul className="page-breadcrumb style-none d-flex justify-content-center">
                        <li><Link to='/'>Home</Link></li>
                        <li className="current-page">Services</li>
                    </ul>
                </div>
                <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two"/>
            </div>

            <div className="fancy-feature-twentyFour">
                <div className="container">
                    <div className="row gx-xxl-5">
                        <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up">
                            <div className="block-style-four">
                                <div className="icon d-flex align-items-end justify-content-center"><img
                                    src="images/icon/icon_35.svg" alt=""/></div>
                                <a><h5>Machine Learning</h5></a>
                                <p>Empower financial insights with tailored AI-driven machine learning solutions.</p>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up"
                             data-aos-delay="100">
                            <div className="block-style-four">
                                <div className="icon d-flex align-items-end justify-content-center"><img
                                    src="images/icon/icon_08.svg" alt=""/></div>
                                <Link to="/services/aml"><h5>AML</h5></Link>
                                <p>Ensure compliance with Anti-Money Laundering (AML) regulations through our AML Independent Audit Report services, enhanced by Regulatory Technology (RegTech) and AI-powered approval systems.</p>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up"
                             data-aos-delay="200">
                            <div className="block-style-four">
                                <div className="icon d-flex align-items-end justify-content-center"><img
                                    src="images/icon/icon_09.svg" alt=""/></div>
                                <a><h5>Data Engineers</h5></a>
                                <p>Optimize data pipelines and infrastructure for seamless financial data management.</p>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up"
                             data-aos-delay="100">
                            <div className="block-style-four">
                                <div className="icon d-flex align-items-end justify-content-center"><img
                                    src="images/icon/icon_10.svg" alt=""/></div>
                                <a><h5>Deep Learning</h5></a>
                                <p>Revolutionize financial insights with advanced AI-powered deep learning solutions.</p>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up"
                             data-aos-delay="200">
                            <div className="block-style-four">
                                <div className="icon d-flex align-items-end justify-content-center"><img
                                    src="images/icon/icon_11.svg" alt=""/></div>
                                <a><h5>Data Mining</h5></a>
                                <p>Extract actionable insights from complex data with SNSTEC's advanced mining solutions.</p>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex" data-aos="fade-up"
                             data-aos-delay="300">
                            <div className="block-style-four">
                                <div className="icon d-flex align-items-end justify-content-center"><img
                                    src="images/icon/icon_12.svg" alt=""/></div>
                                <a><h5>Statistical Modeling</h5></a>
                                <p>Optimize financial strategies with advanced AI-driven statistical modeling solution.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <WorkProcess />
        </div>
    );
};

export default Services;